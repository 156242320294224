@use "@angular/material" as mat;

@import "../node_modules/@ionic/angular/css/padding.css";
@import "../node_modules/@ionic/angular/css/float-elements.css";
@import "../node_modules/@ionic/angular/css/text-alignment.css";
@import "../node_modules/@ionic/angular/css/text-transformation.css";
@import "../node_modules/@ionic/angular/css/flex-utils.css";
@import "../node_modules/@ionic/angular/css/core.css";
@import "../node_modules/@ionic/angular/css/normalize.css";
@import "../node_modules/@ionic/angular/css/structure.css";
@import "../node_modules/@ionic/angular/css/typography.css";
@import "../node_modules/@ionic/angular/css/display.css";

// Define dark and light primary text variables
$dark-primary-text: #000000; // Adjust to your desired dark text color
$light-primary-text: #ffffff; // Adjust to your desired light text color

// Include the core styles for Angular Material
@include mat.core();

// Define Angular Material color palette using CSS variables
$mat-blue: mat.define-palette(
  (
    50: #013f63,
    100: #013f63,
    200: #013f63,
    300: #013f63,
    400: #013f63,
    500: #013f63,
    600: #013f63,
    700: #013f63,
    800: #282a2b,
    900: #013f63,
    A100: #013f63,
    A200: #013f63,
    A400: #013f63,
    A700: #013f63,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);
$my-app-primary: mat.define-palette($mat-blue, 700);

// Assuming $mat-yellow and $mat-red are defined similarly
$mat-yellow: mat.define-palette(
  (
    50: #fffde7,
    100: #fff9c4,
    200: #fff59d,
    300: #fff176,
    400: #ffee58,
    500: #ffeb3b,
    600: #fdd835,
    700: #fbc02d,
    800: #f9a825,
    900: #f57f17,
    A100: #ffff8d,
    A200: #ffff00,
    A400: #ffea00,
    A700: #ffd600,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #000000,
      600: #000000,
      700: #000000,
      800: #000000,
      900: #000000,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  )
);

$mat-red: mat.define-palette(
  (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #f44336,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  )
);

$my-app-accent: mat.define-palette($mat-yellow, 700);
$custom-warn: mat.define-palette($mat-red, 700);

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $custom-warn,
    ),
  )
);

@include mat.all-component-themes($my-app-theme);

// class pour les couleurs des boutons cochés/décochés
.bouton-coche {
  color: #013f63;
}
.bouton-decoche {
  color: #c7d4dd;
}

/** fond de l'appli */
ion-content {
  background-color: #f2f2f2;
}

.mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 0 !important;
  box-sizing: content-box !important;
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  width: unset !important;
}

.mat-mdc-form-field-infix {
  min-height: unset !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: white !important;
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  height: 0;
}

.mdc-list-item__content {
  display: flex !important;
}

.mdc-dialog__title::before {
  content: unset !important;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0px !important;
}

.mat-calendar-table-header th {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 0px !important;
}
.cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-tooltip-word-break: normal;
}
.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  min-width: fit-content !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: #013f63 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-top: 16px;
}

/**
* Barre de défillement custom
*/
/* width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
